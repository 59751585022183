<template>
  <div class="w-full">
    <ul class="p-0 w-full">
      <li class="h-12 text-gray-800">
        <a
          href=""
          class="flex items-center px-4 h-full justify-between rounded-lg"
          :class="[
            all_active ? 'router-link-exact-active router-link-active' : '',
          ]"
          @click.prevent="filterData(-1)"
        >
          <p class="text-sm font-bold truncate">All</p>
          <span
            class="
              w-5
              h-5
              bg-blue-500
              text-white
              border border-blue-500
              hover:bg-white hover:text-blue-600
              rounded-full
              transition-all
              delay-300
              flex
              items-center
              justify-center
              text-sm
            "
          >
            {{ getTotalTemplates(items) }}
          </span>
        </a>
      </li>
      <li class="h-12 text-gray-800" v-for="(item, i) in items" :key="i">
        <a
          href=""
          class="flex items-center px-4 h-full justify-between rounded-lg"
          :class="[
            links[i].is_active && item.id === links[i].id
              ? 'router-link-exact-active router-link-active'
              : '',
          ]"
          @click.prevent="filterData(i)"
        >
          <p class="text-sm font-bold truncate">{{ item.name }}</p>
          <span
            class="
              w-5
              h-5
              bg-blue-500
              text-white
              border border-blue-500
              hover:bg-white hover:text-blue-600
              rounded-full
              transition-all
              delay-300
              flex
              items-center
              justify-center
              text-sm
            "
          >
            {{ item.template_count }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['items', 'links', 'all_active'],
  methods: {
    icon(icon) {
      return require(`@/assets/icon/settings/${icon}-black.svg`)
    },
    filterData(index) {
      this.$emit('click', index)
    },
    getTotalTemplates(items) {
      let total = items.reduce(function(sum, row) {
        return sum + parseFloat(row.template_count)
      }, 0)
      return total
    },
  },
  watch: {},
  computed: {
    // activeClass() {
    //   let currentRoute = this.$router.currentRoute.path
    //     .split("/")
    //     .slice(0, -1)
    //     .join();
    //   if ("link" != currentRoute) {
    //     return;
    //   }
    //   return "active";
    // },
  },
  // mounted() {
  //   console.log(
  //     this.$router.currentRoute.path
  //       .split("/")
  //       .slice(0, -1)
  //       .join()
  //   );
  // },
  created() {},
}
</script>

<style lang="scss" scoped>
.router-link-active {
  background-color: #256baa;
  color: #fff;
}
</style>

<template>
  <base-layout>
    <div v-if="groups.length == 0" class="h-screen vld-parent">
      <loading :active="groups.length == 0" :is-full-page="false" />
    </div>
    <div class="flex bg-white">
      <div class="px-2 pt-4 bg-white min-w-max w-80">
        <TitlePlus :title="pageTitle" :hide-plus="true" />
        <content-section class="border border-gray-400 override">
          <div class="-mb-3">
            <!-- <anchor-tabs :tabs="tabs" /> -->
            <mail-template-nav
              @click="resetLink"
              :links="links"
              :all_active="all_active"
              :items="groups"
            />
          </div>
        </content-section>
      </div>
      <div class="w-full bg-white override-2">
        <!-- <content-section class="override-2">
          <router-view />
        </content-section> -->
        <div class="pt-15 relative px-2">
          <router-view />
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import MailTemplateNav from '@/composites/mail-template/MailTemplateNav'
import { useEndpoints } from '@/composables'

export default {
  name: 'MailTemplatePage',
  components: {
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    BaseLayout,
    ContentSection,
    MailTemplateNav,
  },
  data() {
    return {
      pageTitle: 'Mail Templates',
      groups: [],
      links: [],
      endpoint: useEndpoints.mail.group.index(),
      all_active: true,
    }
  },
  methods: {
    resetLink(index) {
      let payload = ''
      let links = this.links
      let all_is_active = this.all_active
      links.forEach(function(row, key) {
        if (index === key) {
          links[key].is_active = true
          all_is_active = false
          if (links[key].is_active) {
            payload = `&template_group=${row.id}`
          }
        } else {
          links[key].is_active = false
        }
      })
      if (index == -1) {
        all_is_active = true
      }
      this.all_active = all_is_active
      this.links = links
      this.$store.commit('fsTable/appendQsa', { payload })
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/mails/${path}`
    },
    async fetchGroups() {
      const { data } = await this.$http.get(this.endpoint)
      if (data) {
        let links = data.data.map(function(row) {
          return {
            is_active: false,
            id: row.id,
          }
        })
        this.links = links
        this.groups = data.data
      }
    },
  },
  created() {
    this.fetchGroups()
  },
}
</script>

<style lang="scss" scoped>
thead,
tbody tr {
  display: table;
  widows: 100%;
  table-layout: fixed;
}
.pl-main {
  padding-left: 100px;
}
::v-deep .override {
  padding-left: 20px;
  padding-right: 20px;
  // width: 200px;
  nav {
    display: block !important;
    a {
      display: block !important;
      text-align: left;
      margin: 0;
    }
  }
}
::v-deep .override-2 {
  h2 {
    font-size: 22px;
  }
}
</style>
